import React from 'react';
import Navbar from '../components/App/Navbar';
import Footer from '../components/App/Footer';
import Layout from '../components/App/Layout';
import ProjectStart from '../components/Common/StartProject';
import PageBanner from '../components/Common/PageBanner';
import prizeCoupon from '../assets/images/instantwin/prizeCoupon.png';
import prizeDownload from '../assets/images/instantwin/prizeDownload.png';
import prizeLink from '../assets/images/instantwin/prizeLink.png';
import instantwinEntries from '../assets/images/instantwin/instantwin-entries.png';
import instantwinWinners from '../assets/images/instantwin/instantwin-winners.png';
import instantwinEntrant from '../assets/images/instantwin/instantwin-entrant.png';
import OurFeatures from '../components/Instantwin/OurFeatures';
import InstantwinBanner from '../components/Instantwin/InstantwinBanner';
import SEO from '../components/App/SEO';
import instantwinMetaImage from '../assets/images/instantwin/instantwin-meta-image.jpg';
import Image from '../components/UiComponent/image'
import {StaticImage} from 'gatsby-plugin-image';
import WrapperStyle from './instantwin.style'

const Home = () => {
  return (
    <Layout>
      <SEO
        title="インスタントウィン機能｜応募から抽選まで一括管理できるツール"
        desc="個人から中小企業・大企業まで、LINE・TwitterなどのSNSからレシートやアンケートなどの従来の応募方法まで提供しています。抽選、ハッシュタグ機能が充実。"
        banner={instantwinMetaImage}
        pathname={'/instantwin'}
        type={'Article'}
        shortName="インスタントウィン機能"
        createdAt="2021-03-02"
      />
      <Navbar />
      <PageBanner
          pageTitle="インスタントウィン"
          homePageText="Home"
          homePageUrl="/"
          activePageText="インスタントウィン"
      />
      <InstantwinBanner />
      <WrapperStyle>
      <div className="container">

        <section className="services-area pt-100 pb-70">
          <div className="container">
            <div className="section-title">
              <h2>賞品タイプ</h2>
              <p>応募条件を満たした応募者には、様々なデジタル賞品をその場で提供できます。</p>
            </div>

            <div className="row">
              <div className="col-lg-4 col-md-4">
                <div className="single-services-item-box">
                  <div className="icon" style={{width: '250px', margin:'auto'}}>
                    <StaticImage src="../assets/images/instantwin/prizeCoupon.png" alt="features" />
                  </div>
                  <h3>
                      クーポン
                  </h3>
                  <p>ECサイトのクーポンやAmazonギフトなど番号で配布するクーポンであれば全て対応可能です。</p>
                </div>
              </div>

              <div className="col-lg-4 col-md-4">
                <div className="single-services-item-box">
                  <div className="icon" style={{width: '250px', margin:'auto'}}>
                    <StaticImage src="../assets/images/instantwin/prizeDownload.png" alt="features" />
                  </div>
                  <h3>
                      ダウンロード
                  </h3>
                  <p>PDFファイルや非公開の音声ファイル、電子ファイルのダウンロードを応募の特典として提供できます。</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="single-services-item-box">
                  <div className="icon" style={{width: '250px', margin:'auto'}}>
                    <StaticImage src="../assets/images/instantwin/prizeLink.png" alt="features" />
                  </div>
                  <h3>
                      リンク
                  </h3>
                  <p>非公開ページや応募者のみが閲覧できるリンクを設定することが可能です。</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="our-mission-area pb-100">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12">
                <div className="our-mission-content">
                  <div className="content">

                    <h2>60以上の応募方法</h2>
                    <p>Twitter、Facebook、LINEといった主要なSNSはもちろん、AppleMusicやSpotifyなどたくさんのプラットフォームを連携。従来のクイズや応募コード、メルマガ登録などと組み合わせることができます。</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-12">
                <div className="our-mission-image">
                  <StaticImage src="../assets/images/instantwin/instantwin-entries.png" alt="インスタントウィンの応募方法" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="about-area pb-100">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12">
                <div className="about-img">
                  <StaticImage src="../assets/images/instantwin/instantwin-winners.png" alt="応募から抽選・当選まで一括管理" />
                </div>
              </div>

              <div className="col-lg-6 col-md-12">
                <div className="about-content">
                  <div className="content">

                    <h2>応募から当選まで一元管理</h2>
                    <p>応募者が応募条件を満たすとその場で、賞品を配布することができます。当選・落選から応募者の一覧まで全て一括管理できるため、インスタントウィンを設定すれば、終了日がくるのを待つだけです。</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="our-mission-area pb-100">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12">
                <div className="our-mission-content">
                  <div className="content">
                    <h2>応募者情報のカスタマイズ</h2>
                      <p>応募時に必要な住所や電話番号、その他にもチェックボックスでの同意やアンケートのための選択式の質問といった多くの入力項目に対応できます。</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-12">
                <div className="our-mission-image">
                  <StaticImage src="../assets/images/instantwin/instantwin-entrant.png" alt="service"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <OurFeatures/>
      <ProjectStart />
      </WrapperStyle>
      <Footer />
    </Layout>
  )
};

export default Home