import styled from 'styled-components';
export default  styled.div`
  @media only screen and (max-width: 767px) {
    .single-services-item-box .icon img {
      width: 220px;
    }
    .single-services-item-box {
      margin-bottom: 0;
    } 
  }
 
`;

