import React from 'react';
import {Link} from 'gatsby';
import {StaticImage} from 'gatsby-plugin-image';

const StartProject = () => {
    return (
        <section className="project-start-area bg-color ptb-100">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="project-start-image">
                            <StaticImage src="../../assets/images/project-start1.png" alt="banner" />
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="project-start-content">
                            <h2>リタッチでキャンペーンを試してみませんか？</h2>
                            <p>アカウント登録は無料ですぐにお試しいただけます。クレジットカードの登録も不要です。</p>
                            <Link href="/signup" className="default-btn">
                                <i className="flaticon-right"></i> 今すぐ無料で試す<span></span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className="vector-shape9">
                <StaticImage src="../../assets/images/shape/vector-shape9.png" alt="shape" />
            </div>
            <div className="vector-shape10">
                <StaticImage src="../../assets/images/shape/vector-shape10.png" alt="shape" />
            </div>
        </section>
    )
};

export default StartProject