import React from 'react';
import {StaticImage} from 'gatsby-plugin-image';

const OurFeatures = () => {
    return (
        <section className="services-area pt-100 pb-70 bg-f1f8fb">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <StaticImage src="../../assets/images/star-icon.png" alt="features" />
                        Our Futures
                    </span>

                    <h2>こんな使い方ができます</h2>
                    <p>お客様のビジネスによって提供できる賞品を自由に変更できます。ビジネスを加速させるより魅力的な賞品を提供してください。</p>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-services-item-box">
                            <h3>アーティストの非公開音源</h3>
                            <div className="icon">
                                <StaticImage src="../../assets/images/instantwin/icon-sound.png" alt="feature" />
                            </div>
                            <p>アーティストの非公開音源や、未発表の音源ファイルをダウンロードを提供</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-services-item-box">
                            <h3>ECサイトのクーポン</h3>
                            <div className="icon">
                                <StaticImage src="../../assets/images/instantwin/icon-ec-coupon.png" alt="feature" />
                            </div>
                            <p>ECサイトで使える季節によって変わる目玉商品の割引クーポンを提供</p>
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-services-item-box">
                            <h3>フェスの非公開情報</h3>
                            <div className="icon">
                                <StaticImage src="../../assets/images/instantwin/icon-private-info.png" alt="feature"/>
                            </div>
                            <p>フェスティバルで参加アーティストを先行で閲覧できるURLを提供</p>
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-services-item-box">
                            <h3>ブロガーのハウツー</h3>
                            <div className="icon">
                                <StaticImage src="../../assets/images/instantwin/icon-pdif.png" alt="feature" />
                            </div>
                            <p>ブログでは書ききれない状況や、ディープな情報をPDFとして提供</p>
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-services-item-box">
                            <h3>ゲームのシークレットキー</h3>
                            <div className="icon">
                                <StaticImage src="../../assets/images/instantwin/icon-sercret-key.png" alt="feature" />
                            </div>
                            <p>ゲーム上でのレアアイテムを獲得できるシークレットキーを提供</p>
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-services-item-box">
                            <h3>ギフトカード</h3>
                            <div className="icon">
                                <StaticImage src="../../assets/images/instantwin/icon-gift.png" alt="feature" />
                            </div>
                            <p>すぐに使える、AMAZONギフトやGoogle Playギフトを提供</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
};

export default OurFeatures