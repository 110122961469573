import React from 'react';
import instantwinBanner from '../../assets/images/instantwin/instantwin-banner.png';
import WrapperStyle from './InstantwinBanner.style';
import Image from '../../components/UiComponent/image'
import {StaticImage} from 'gatsby-plugin-image';

const InstantwinBanner = () => {
    return (
        <WrapperStyle>
        <section className="solutions-area">
            <div className="container" style={{maxWidth: '800px', margin:'auto'}}>
              <StaticImage src="../../assets/images/instantwin/instantwin-banner.png" className="banner" alt="インスタントウィン"/>
            </div>
        </section>
        </WrapperStyle>
    )
};

export default InstantwinBanner;